import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import queryString from 'query-string';

import { colors } from 'common/styles';
import media from 'mediaTemplate';
import { useSearch } from 'common/components/SimilaritySearch/hooks/useSearch';
import { ResultItem } from 'common/components/SimilaritySearch/components/ResultItem';

type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps & RouteComponentProps;

const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${colors.white};
`;

const SearchResults: React.FC<Props> = ({ location, currency }) => {
  const { results, isLoading, handleSearch } = useSearch();
  const { q: query } = queryString.parse(location.search);

  useEffect(() => {
    if (query) {
      handleSearch(query as string, true);
    }
  }, [handleSearch, query]);

  if (!query) {
    return (
      <PageWrapper>
        <Container>
          <NoResults>
            <FormattedMessage
              id="NoSearchQuery"
              defaultMessage="Please enter a search term"
            />
          </NoResults>
        </Container>
      </PageWrapper>
    );
  }

  if (isLoading) {
    return (
      <PageWrapper>
        <Container>
          <LoadingWrapper>
            <Spinner />
            <LoadingText>
              <FormattedMessage id="Searching" defaultMessage="Searching..." />
            </LoadingText>
          </LoadingWrapper>
        </Container>
      </PageWrapper>
    );
  }

  if (!results || (!results.tours.length && !results.locations.length)) {
    return (
      <PageWrapper>
        <Container>
          <NoResults>
            <FormattedMessage
              id="NoSearchResults"
              defaultMessage={`No results found for "${query}"`}
            />
          </NoResults>
        </Container>
      </PageWrapper>
    );
  }

  const showToursFirst = results.bestMatchType === 'tour';
  const sections = showToursFirst
    ? [
        { items: results.tours, type: 'tours' },
        { items: results.locations, type: 'locations' },
      ]
    : [
        { items: results.locations, type: 'locations' },
        { items: results.tours, type: 'tours' },
      ];

  return (
    <PageWrapper>
      <Container>
        <SearchHeader>
          <SearchQuery>
            <FormattedMessage
              id="SearchResultsFor"
              defaultMessage={`Search results for "${query}"`}
              values={{ query }}
            />
          </SearchQuery>
        </SearchHeader>

        {sections.map(
          ({ items, type }) =>
            items.length > 0 && (
              <Section key={type}>
                <SectionTitle>
                  <FormattedMessage
                    id={type === 'tours' ? 'Tours' : 'Locations'}
                    defaultMessage={type === 'tours' ? 'Tours' : 'Locations'}
                  />
                </SectionTitle>
                <ResultsGrid>
                  {items.map((item, index) => (
                    <ResultItem
                      key={`${type}-${index}`}
                      result={item}
                      variant="result"
                    />
                  ))}
                </ResultsGrid>
              </Section>
            ),
        )}
      </Container>
      {/* <ScrollToTopButton /> */}
    </PageWrapper>
  );
};

const mapState = (state: RootState) => ({
  currency: state.currency.currency,
});

const connector = connect(mapState);

export default withRouter(connector(SearchResults));

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${colors.teal};
`;

const SearchQuery = styled.h1`
  font-size: 2.4rem;
  color: ${colors.almostBlack};
  margin: 0;

  ${media.tablet`
    font-size: 2rem;
  `}
`;

const Section = styled.section`
  margin-bottom: 4rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: ${colors.orange[500]};
  margin: 3rem 0 2rem;
  padding-bottom: 1rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 1rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${colors.teal};
  }
`;

const ResultsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  align-items: start;

  ${media.tablet`
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 1.5rem;
  `}
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 2rem;
`;

const LoadingText = styled.div`
  font-size: 1.8rem;
  color: ${colors.darkGrey};
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid ${colors.orange[300]};
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const NoResults = styled.div`
  text-align: center;
  font-size: 1.8rem;
  color: ${colors.darkGrey};
  margin: 4rem 0;
`;
