import * as React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { MainSearchBox } from 'common/components';

import media from '../../mediaTemplate';

export const Slider: React.SFC = () => {
  const intl = useIntl();
  return (
    <Container>
      <InnerContainer>
        <MainSearchBox locale={intl.locale} />
      </InnerContainer>
    </Container>
  );
};

export default Slider;

const InnerContainer = styled.div`
  max-width: 800px;
  margin: 1rem auto;
  min-height: 450px;
  ${media.phone`
    flex-direction: column;
    align-items: center;
  `};
`;

const Container = styled.div`
  padding-top: 1rem;
  box-shadow: 1px 1px 10px #999;
  background-image: url(/img/swiss.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
