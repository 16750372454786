import styled from 'styled-components';

import { colors } from 'common/styles';
import media from 'mediaTemplate';

export const Header = styled.h1`
  text-align: center;
  color: ${props => props.color || colors.white};
  z-index: 20;
  font-size: 5em;
  margin: 0;

  ${media.desktop`
    font-size: 6rem;
  `}


  ${media.small`
    font-size: 5rem;
  `}

  ${media.tablet`
    font-size: 5rem;
  `};

  ${media.phone`
    font-size: 5rem;
  `};
`;

export const Subheader = styled.h3<{ color?: string }>`
  text-align: center;
  z-index: 20;
  color: ${props => props.color || colors.white};

  ${media.desktop`
    font-size: 2rem;
  `}
  ${media.tablet`
    font-size: 1.5rem;
  `};
`;
