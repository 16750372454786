import * as React from 'react';

import { useIntl } from 'react-intl';
import { Route, Switch } from 'react-router-dom';

import { OAUTH_CALLBACK } from './paths';

import BancontactProcessingPage from 'commerce/components/bancontact-processing-page';
import { FourOhFour, Landing } from 'common/components';
import SearchResults from 'tours/components/search-results';

const AboutUs = (locale = 'en') =>
  React.lazy(() =>
    import(`./static/${locale}/AboutUs` /* webpackChunkName: "about_us" */),
  );

const ConfirmationPage = React.lazy(() =>
  import(
    './confirmation/components/confirmation-page' /* webpackChunkName: "confirmation_page" */
  ),
);

const Contactus = (locale = 'en') =>
  React.lazy(() =>
    import(`./static/${locale}/Contactus` /* webpackChunkName: "contact_us" */),
  );
const DestinationsPage = React.lazy(() =>
  import(
    `./static/DestinationsPage` /* webpackChunkName: "destinations_page" */
  ),
);
const Distributors = React.lazy(() =>
  import(`./static/Distributors` /* webpackChunkName: "distributors_page" */),
);
const Faq = (locale = 'en') =>
  React.lazy(() =>
    import(`./static/${locale}/Faq` /* webpackChunkName: "faq" */),
  );
const AffiliateTermsAndConditions = (locale = 'en') =>
  React.lazy(() =>
    import(
      `./static/${locale}/affiliate-terms-and-conditions` /* webpackChunkName: "affiliate_terms_and_conditions" */
    ),
  );
const GeneralTermsAndConditions = (locale = 'en') =>
  React.lazy(() =>
    import(
      `./static/${locale}/GeneralTermsAndConditions` /* webpackChunkName: "general_terms_and_conditions" */
    ),
  );
// const HotelDetails = React.lazy(() =>
//   import('./hotels/components/Details' /* webpackChunkName: "hotel_details" */),
// );
// const HotelsSearchResultsContainer = React.lazy(() =>
//   import(
//     './hotels/components/HotelsSearchResultsContainer' /* webpackChunkName: "hotel_search_results" */
//   ),
// );
const LegalDisclosures = (locale = 'en') =>
  React.lazy(() =>
    import(
      `./static/${locale}/LegalDisclosures` /* webpackChunkName: "legal_disclosures" */
    ),
  );
const Magazine = React.lazy(() =>
  import(`./static/Magazine` /* webpackChunkName: "magazine" */),
);
const MagazineArticle = React.lazy(() =>
  import(`./static/MagazineArticle` /* webpackChunkName: "magazine_article" */),
);
const NewsletterPage = (locale = 'en') =>
  React.lazy(() =>
    import(
      `./static/${locale}/NewsletterPage` /* webpackChunkName: "newsletter_page" */
    ),
  );
const OauthHandler = React.lazy(() =>
  import(
    './auth/components/OauthHandler' /* webpackChunkName: "oauth_handler" */
  ),
);
const Press = (locale = 'en') =>
  React.lazy(() =>
    import(`./static/${locale}/Press` /* webpackChunkName: "press" */),
  );
const Privacy = (locale = 'en') =>
  React.lazy(() =>
    import(`./static/${locale}/Privacy` /* webpackChunkName: "privacy" */),
  );
const ReviewPage = React.lazy(() =>
  import(`./common/components/reviews` /* webpackChunkName: "reviews" */),
);
const ResultsPage = React.lazy(() =>
  import(
    './tours/components/results-page' /* webpackChunkName: "search_results_tours" */
  ),
);
const ShoreExcursions = React.lazy(() =>
  import(
    './tours/components/ShoreExcursions' /* webpackChunkName: "shore_excursions" */
  ),
);
const Suppliers = React.lazy(() =>
  import(`./static/Suppliers` /* webpackChunkName: "suppliers_page" */),
);
const TermsOfUse = (locale = 'en') =>
  React.lazy(() =>
    import(
      `./static/${locale}/TermsOfUse` /* webpackChunkName: "terms_of_use" */
    ),
  );
const TourDetails = React.lazy(() =>
  import(
    './tours/components/DetailsPage' /* webpackChunkName: "tour_details" */
  ),
);
const ToursAndActivitiesPage = React.lazy(() =>
  import(
    `./static/ToursAndActivitiesPage` /* webpackChunkName: "tours_and_activities_page" */
  ),
);
const ToursForCruisesPage = React.lazy(() =>
  import(
    `./static/ToursForCruisesPage` /* webpackChunkName: "tours_for_cruises_page" */
  ),
);
const UserProfile = React.lazy(() =>
  import(
    './auth/components/UserProfile' /* webpackChunkName: "user_profile" */
  ),
);
const Verification = React.lazy(() =>
  import(
    './common/components/Verification' /* webpackChunkName: "verification" */
  ),
);

const TourProductPage = React.lazy(() =>
  import(
    './tours/components/product-page' /* webpackChunkName: "new_product_page" */
  ),
);

const NewCheckoutPage = React.lazy(() =>
  import(
    './commerce/components/checkout-page'
    /* webpackChunkName: "new_checkout_page" */
  ),
);

const Routes = () => {
  const intl = useIntl();
  return (
    <React.Suspense
      fallback={<div style={{ minHeight: 'calc(100vh - 60px)' }} />}
    >
      <Switch>
        <Route
          exact={true}
          path="/:lang?/confirmation"
          component={ConfirmationPage}
        />
        <Route
          exact={true}
          path="/bancontact-processing"
          component={BancontactProcessingPage}
        />
        <Route
          exact={true}
          path="/:lang?/new/checkout/:step(personal_info|traveler_info|questions|payment)"
          component={NewCheckoutPage}
        />
        <Route exact={true} path="/:lang(en|de|zh-Hans)?" component={Landing} />
        <Route path="/:lang?/distributors" component={Distributors} />
        <Route
          exact={true}
          path="/:lang?/tourz/:city/:productId"
          component={TourProductPage}
        />

        {/* <Route
          exact={true}
          path="/:lang?/hotels/:city"
          component={HotelsSearchResultsContainer}
        /> */}
        {/* <Route
          exact={true}
          path="/:lang?/hotels/:city/:hotelId"
          component={HotelDetails}
        /> */}
        <Route path="/:lang?/aboutUs" component={AboutUs(intl.locale)} />
        <Route exact={true} path="/:lang?/magazine" component={Magazine} />
        <Route
          exact={true}
          path="/:lang?/magazine/:type/:destinationId"
          component={MagazineArticle}
        />
        <Route
          path="/:lang?/newsletter"
          component={NewsletterPage(intl.locale)}
        />
        <Route path="/:lang?/press" component={Press(intl.locale)} />
        <Route path="/:lang?/contactUs" component={Contactus(intl.locale)} />
        <Route path="/:lang?/faq" component={Faq(intl.locale)} />
        <Route path="/:lang?/topDestinations" component={DestinationsPage} />
        <Route
          path="/:lang?/toursAndActivities"
          component={ToursAndActivitiesPage}
        />
        <Route
          exact={true}
          path="/:lang?/toursForCruises"
          component={ToursForCruisesPage}
        />
        <Route path="/:lang?/search" component={SearchResults} exact={true} />
        <Route
          path="/:lang?/toursForCruises/:routeId"
          component={ShoreExcursions}
        />
        <Route path="/:lang?/termsOfUse" component={TermsOfUse(intl.locale)} />
        <Route
          path="/:lang?/affiliate-terms"
          component={AffiliateTermsAndConditions(intl.locale)}
        />
        <Route
          path="/:lang?/generalTermsAndConditions"
          component={GeneralTermsAndConditions(intl.locale)}
        />
        <Route path="/:lang?/suppliers" component={Suppliers} />
        <Route path="/:lang?/privacy" component={Privacy(intl.locale)} />
        <Route
          path="/:lang?/legalDisclosures"
          component={LegalDisclosures(intl.locale)}
        />
        <Route path="/:lang?/dashboard" component={UserProfile} />
        <Route path="/:lang?/verification" component={Verification} />
        <Route path={OAUTH_CALLBACK} component={OauthHandler} />
        <Route path="/:lang?/fourOhFour" component={FourOhFour} />
        <Route path="/:lang?/review/:productId" component={ReviewPage} />
        <Route
          exact={true}
          path="/:lang?/:productType/:city"
          component={ResultsPage}
        />
        <Route
          exact={true}
          path="/:lang?/:productType/:city/:tourTitleId"
          component={TourDetails}
        />
        <Route component={FourOhFour} />
      </Switch>
    </React.Suspense>
  );
};

export default Routes;
