import { useState, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

import { SearchResults } from '../types/types';

export function useSearch() {
  const intl = useIntl();
  const [results, setResults] = useState<SearchResults>();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const abortControllerRef = useRef<AbortController | null>(null);

  const debouncedSearch = useCallback(
    debounce(async (searchQuery: string, expanded = false) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      if (!searchQuery) {
        setResults(undefined);
        setIsLoading(false);
        return;
      }

      abortControllerRef.current = new AbortController();
      try {
        const response = await fetch(
          `https://2ozas7u3ng.execute-api.us-east-1.amazonaws.com/default/semantic-search?query=${encodeURIComponent(
            searchQuery,
          )}&lang=${intl.locale}&expanded=${expanded}`,
          { signal: abortControllerRef.current.signal },
        );
        const data = await response.json();
        setResults(data);
        setIsLoading(false);
      } catch (error) {
        if ((error as any)?.name !== 'AbortError') {
          console.error('Search error:', error);
          setIsLoading(false);
        }
      }
    }, 50),
    [intl.locale],
  );

  const handleSearch = useCallback(
    (searchQuery: string, expanded = false) => {
      setQuery(searchQuery);
      setIsLoading(true);
      debouncedSearch(searchQuery, expanded);
    },
    [debouncedSearch],
  );

  return { results, isLoading, query, setQuery, handleSearch };
}
