import footer from './footer.zh-Hans';
import header from './header.zh-Hans';
import travelcouper from './travelcouper.zh-Hans';

const messages = {
  ...header,
  ...footer,
  ...travelcouper,
  AboutUs: '关于我们',
  AcceptedPaymentMethodsColon: '接受的付款选项:',
  AccountStatusAndRecentActivity: '成员资格状态和以前的操作',
  Activities: '活动',
  Activity: '活动',
  AddGuests: '添加来宾',
  AddGuestsParticipantsColon: '添加客人(至少必须指定主要旅客的姓名):',
  Address: '地址',
  AddRoom: '添加房间',
  Adults: '成人',
  All: '所有',
  AllYearRound: '全年',
  AlreadyHaveAnAccount: '您已经拥有帐户了吗?',
  And: '和',
  Any: '随机',
  Attractions: '景点',
  Back: '返回',
  BackToMagazine: '回到杂志',
  BackToResults: '返回结果',
  BecomeAPartner: '成为合作伙伴',
  BookAdventure: '查找游览。预订体验!',
  BookBy: '预订至',
  BookingDetails: '预订详情',
  BookingsAndReservations: '预订和预订',
  BookNow: '立即预订',
  BookThisTour: '预订此旅游',
  Breakfast: '早餐',
  Budget: '预算',
  By: '从',
  ByName: '按名称',
  CancellationRulesColon: '取消：',
  Categories: '类别',
  Checkout: '结帐',
  CheckoutAsGuest: '继续作为客人',
  CheckoutTravelcoupMagazine: '从旅行库普杂志获得灵感',
  Children: '孩子',
  ChooseYour: '寻找最好的',
  ChooseYourPort: '选择您的端口',
  City: '城市',
  CityPasses: '城市通行证',
  Collaboration: '与我们合作',
  CommentsSpecialRemarks: '特别评论',
  Company: '做',
  CompareDeals: '查找和比较最佳交易',
  Confirmation: '确认',
  ContactUs: '联系',
  Continue: '进一步',
  ContinueAsA: '进一步比或',
  CreateAnAccount: '创建帐户',
  Cruise: '巡航',
  CruiseLine: '航运',
  Dashboard: '仪表 板',
  Dates: '数据',
  DealsIn: '优惠在',
  Destinations: '目的地',
  Details: '细节',
  DidntGetVerificationEmail: '未收到验证电子邮件?',
  Discover: '发现',
  DiscoverHotelsIn: '我们的酒店提供',
  DiscoverMoreHotelsIn: '了解更多酒店优惠',
  DiscoverMoreToursActivities: '发现更多旅游和活动',
  DiscoverToursIn: '发现旅游和活动',
  DoYouHave: '你有一个',
  EditYourAccountDetails: '编辑个人资料信息',
  EgHotelName: '例如酒店名称',
  EMail: '电子邮件',
  EnterDestination: '告诉您的目的地',
  EnterYourEmail: '电子邮件地址',
  Extra: '工具',
  Faq: '常见问题',
  Filter: '滤波器',
  FilterResults: '筛选结果',
  FindAHotelIn: '在 中查找酒店',
  FindATripBookAnAdventure: '找到一个旅行。预约冒险。',
  FindCompareBestDeals: '查找和比较最好的交易!',
  FirstName: '名字',
  Found: '发现',
  FreeCancellation: '免费取消',
  FrequentlyAskedQuestions: '常见问题',
  From: '从',
  From_For_Prices: '关闭',
  FullAddress: '街道和房屋号码',
  FullBoard: '板',
  FullName: '名字',
  GeneralTermsAndConditions: '一般条款和条件',
  GoTravelDiscover: '走。旅行。发现。',
  GreatSavings: '节省大量成本',
  Guest: '客人',
  HalfBoard: '半板',
  Highlights: '突出',
  HighToLow: '价格下降',
  HintsAndTips: '备注和提示',
  Home: '家',
  HotelDescription: '酒店描述',
  HotelName: '酒店名称',
  Hotels: '酒店',
  HotelTransfer: '酒店接送服务',
  HoursParenthesis: '小时',
  IfYourBookingIsFlaggedAsOnRequest:
    '如果这是一次要求预订,我们将在退房后立即处理提供商的确认。如果没有旅游,我们将立即退还您的购买金额',
  Important: '重要',
  InCaseTheTourIsNotAvailable: '如果您的旅游不可用,我们将立即退还您的钱。',
  InspireYourSelfTravelTheWorld: '激励自己,周游世界',
  Languages: '语言',
  LastName: '姓',
  LatestReservations: '最新预订',
  Legal: '法律',
  LegalDisclosures: '印记',
  Loading: '要加载',
  LoadMoreTopDestinations: '更多热门目标商店',
  LoadMoreTours: '更多旅游商店',
  LocationMap: '地图',
  Login: '登录',
  LogoutLinkExMark: '注销！',
  LowToHigh: '价格提升',
  Magazine: '杂志',
  MessageToTravelcoup: '给 Travelcoup 写一条消息',
  MessageToTravelcoupDots: '消息到旅行库...',
  MobileVouchersAccepted: '移动机票',
  MoreActivities: '更多活动',
  MoreInformation: '更多信息',
  MostCommonQuestonsAnswered: '最常问的问题',
  MostLovedHotels: '最受欢迎的酒店',
  MostLovedTours: '最受欢迎的旅游',
  MyAccount: '我的个人资料',
  MyReservations: '我的预订',
  NameOfParticipant: '旅客',
  NeverSettleNeverRest: '永不安顿。从不休息。',
  NewsLetter: '通讯',
  NextAvailableDate: '下一个可用日是',
  Night: '晚上',
  NoCreditCardFees: '无信用卡费用',
  NonRefundable: '不予退款',
  NoRoomsFound: '未找到可用的酒店。请更改搜索条件,然后重试。',
  NoteColon: '其他信息:',
  NoThanks: '不，谢谢。',
  Now: '现在',
  OfferExpiresInColon: '此优惠有效期至:',
  On: '用于创建最高级',
  OnManyRooms: '对于大多数房间',
  OnRequestBooking:
    '很遗憾,我们�����在预订后提出供应要求。在大多数情况下,地点应该仍然可用,您应该在几个小时内收到确认。如果提供商不再可用,我们将立即取消预订,并免费提供。',
  Open: '打开',
  Or: '或',
  OrderNumberColon: '确认编号:',
  PartnerAndTourOperatorExtranet: '合作伙伴 + 旅游提供商/旅行提供商外联网',
  Payment: '付款',
  PaymentFreeOfCharge: '免费付款',
  PhoneNumber: '电话号码',
  PleaseEnterResidenceInformaition: '请输入您的酒店名称',
  PleaseEnterStartingTime: '请指定所需的开始时间',
  PleaseNoteThatSinceOnRequest:
    '请注意,您的预订是"应要求"。我们将与您联系,并将在未来 24 小时内与您联系。',
  PleaseVerifiyYourEmail:
    '请确认您的电子邮件地址,查看您的预订并更改您的个人资料信息。',
  PleaseVerifyYourEmailHistory: '请确认您的电子邮件地址,查看您的预订历史记录',
  PopularDestinations: '热门目的地',
  Port: '港口',
  PostalCode: '邮政编码',
  PrintPaymentConfirmation: '打印付款确认',
  PrintTicketsAtHome: '打印票证@home',
  Privacy: '隐私政策',
  ProceedToCheckout: '继续结帐',
  processingDots: '正在进行中...',
  ProvideCardInformationBelowColon: '输入您的信用卡号:',
  Rating: '评分',
  RecentReservations: '最新预订',
  RedeemVoucherCode: '兑换凭证代码',
  RefineSearch: '优化搜索',
  ResendVerificationEmail: '重新发送验证电子邮件',
  ReviewedBy: '审查者',
  Reviews: '评论',
  RoomAmenities: '房间',
  RoomOnly: '仅房间',
  Rooms: '房间',
  RoutePlan: '路线',
  Save: '保存',
  Search: '搜索',
  SearchResultsFor: '搜索结果 "{query}"',
  Select: '选择...',
  SelectADate: '选择日期',
  SelectCountry: '选择您所在的国家/地区',
  SelectLanguage: '选择语言',
  SelectTime: '选择时间',
  Send: '广播',
  SendMessage: '发送消息',
  Ship: '船',
  ShoreExcursions: '海岸游览',
  ShowAll: '查看所有内容',
  ShowLess: '显示更少',
  ShowMore: '显示更多',
  SignUpForOurNewsletter: '订阅时事通讯',
  Sleeps: '人',
  SortBy: '排序方式',
  SpecialPromotion: '特别优惠',
  SpecialRequests: '您有什么特别要求,你想与我们分享吗?',
  'SpinnerWait-hotels': '请稍等。我们正在寻找最适合你的酒店。',
  'SpinnerWait-tours': '请稍等。我们正在寻找最适合你的旅游。',
  Stars: '明星',
  StartingFrom: '关闭',
  StartingPoint: '起点',
  StartingPointColon: '起点：',
  StartingTime: '开始',
  StateProvince: '州/州/地区',
  Street: '街',
  SubmitPayment: '确认付款',
  subtotal: '次 全 ��',
  Summary: '总结',
  TariffNotes: '关税信息',
  TermsOfUse: '条款',
  ThankYouForChoosingTravelcoupConfirmation:
    '感谢您选择旅行库普。事务已完成。下面你会发现你的机票和付款确认。您的真诚,您的旅行团队',
  ThankYouForChoosingTravelcoupSteps:
    '感谢您选择旅行库普。要完成预订流程,请填写以下表格并选择您喜欢的付款方式。结帐成功后,您将通过电子邮件立即收到您的旅游/酒店优惠券。',
  ThankYouForPurchasingATicket: '感谢您预订travelcoup.com。事务已完成。',
  Ticket: '票',
  Time: '时间',
  TopDestinations: '热门目的地',
  TopSeller: '畅销书',
  TotalColon: '总：',
  TourOperatedByColon: '旅游经营者:',
  TourOperator: '旅游经营者',
  Tours: '旅行',
  ToursAndActivities: '旅游和活动',
  ToursAndActivitiesIn: '旅游和活动',
  ToursAvailable: '提供旅游',
  ToursForCruises: '游船之旅',
  Travel: '旅行',
  TravelBy: '前往',
  Travelcouper: '你是一个什么样的旅行者？',
  TravelcoupVoucherCode: '旅行券代码',
  TravelerNames: '旅客姓名',
  TravelExperienceAsUniqueAsYouAre: '旅行体验与您一样独特。',
  TravelscapeLLCTerms: '旅行景观有限责任公司条款',
  Type: '类型',
  Until: '直到',
  UponRequestChosen: '所选日期可应要求提供',
  UponRequestOnly: '应要求',
  ViewAll: '查看所有',
  ViewMap: '地图',
  ViewMoreToursAndActivities: '发现更多旅游和活动',
  Was: '是',
  YouHaveNotBookedAnyTour: '您尚未预订旅游或酒店',
  YouMightLike: '你可能也喜欢这样',
  YourInformation: '您的数据',
  YourPreference: '你的偏好',
  YouWillReceiveAnEmailWithThePayment:
    '您将很快收到一封电子邮件,其中包含您的付款确认。',

  // tours for cruises page
  BookShoreExcursions: '预订岸上游览',
  NumberOne: '海岸游览#1',

  Customizable: '灵活性',
  CustomizableText: '预订最适合您的旅游,并根据您的意愿探索每个港口',

  GreatDeals: '优惠优惠',
  GreatDealsText: '旅游库普有最好的旅游在最优惠的价格。',

  IndividualTour: '个人旅游',
  IndividualTourText: '没有排长队或令人不快的附加义务',

  MakeTheMost: '充分利用您的船程',

  Partnered: '伙伴',
  PartneredText: 'Travelcoup 与船运公司携手合作,因此您不必担心错过船舶',

  RiverCruises: '河上游船',
  SeaCruises: '深海巡航',

  Why: '为什么',

  AidaAdventure: '您的巡航体验从这里开始',
  AidaHighlights: '发现 2020 年 AIDA 亮点',
  AndCruise: '和旅行',
  AndShip: '和一艘船',
  PickCruiseLine: '选择运输公司',
  RiverAdventure: '游船体验从这里开始',
  VivaHighlights: '发现 VIVA 亮点 2019',

  Arrive: '到来',
  Ashore: '国家方案。',
  Depart: '离开',
  DiscoverShoreExcursions: '通过单独的岸上游览探索您的游船城市',
  YourIndividualProgram: '您的个人',

  Billed: '帐单',
  CheckoutMethod: '选择结账方式',
  Direct: '直接',

  SelectDifferentTimeFor: '选择不同的时间',

  Error404: '404-错误',
  Oops: '哦',
  PageNotFound: '有些不对劲',
  SomethingWentWrong: '抱歉，我们找不到您要查找的页面。',
  Wishlist: '愿望清单',
  YourWishlist: '您的心愿单',

  OurInspiration: '我们的灵感',
  ForYou: '为了你',

  SeatingSections: '落座部分',
  home: '家',

  // Search suggestions
  similaritySearchCount: '{count} 个{count, plural, one {结果} other {结果}}',
  similaritySearchToursTitle: '相关旅游',
  similaritySearchLocationsTitle: '相关目的地',
  similarityShowMoreResults: '更多结果',
  similaritySearching: '搜索中...',
  similarityNoQuery: '请输入搜索词',
  similarityNoResults: '未找到与"{query}"相关的结果',
  similarityResultsFor: '"{query}"的搜索结果',
};

export default messages;
