import styled from 'styled-components';

import { colors } from 'common/styles';
import media from 'mediaTemplate';

interface VariantProps {
  variant: 'suggestion' | 'result';
}

export const ResultsWrapper = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.white};
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
  z-index: 1000;

  @media (min-height: 1000px) {
    max-height: calc(100vh - var(--distance-from-top, 200px) - 20px);
    overflow-y: auto;
  }
`;

export const ColumnsContainer = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex: 1;
  padding: 1.6rem;

  @media (min-width: 1024px) {
    &:first-child {
      border-right: 1px solid ${colors.lightGrey};
    }
  }
`;

export const SectionHeader = styled.div`
  background: ${colors.white};
  padding: 0.8rem 0;
  margin-bottom: 0.8rem;
  border-bottom: 1px solid ${colors.lightGrey};
`;

export const ResultsSection = styled.div`
  padding: clamp(1rem, 2vw, 1.6rem);
  padding-top: 0;
`;

export const SectionTitle = styled.h2`
  color: ${colors.orange[300]};
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 1rem 0;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding-bottom: 0.8rem;
  border-bottom: 2px solid ${colors.teal};

  ${media.tablet`
    font-size: 1.2rem;
  `}
`;

export const ResultImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SectionCount = styled.span`
  color: ${colors.darkGrey};
  font-size: 1.2rem;
  margin-left: auto;
  font-weight: normal;
`;

export const ResultItem = styled.div<VariantProps>`
  padding: 0.4rem 0.8rem;
  margin-bottom: 0.3rem;
  border-radius: 8px;
  background: ${colors.white};
  transition: all 0.2s ease;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ variant }) =>
    variant === 'suggestion'
      ? `
    &:hover {
      background: ${colors.lightGrey};
    }
  `
      : `
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    }
  `}
`;

export const ResultLink = styled.a<VariantProps>`
  ${({ variant }) =>
    variant === 'result' &&
    `
    height: 100%;
    display: flex;
    flex-direction: column;
  `}
  text-decoration: none;
  color: inherit;
  width: 100%;
  gap: ${({ variant }) => (variant === 'suggestion' ? '0.8rem' : '1.2rem')};
`;

export const ResultContent = styled.div<VariantProps>`
  display: flex;
  gap: ${({ variant }) => (variant === 'suggestion' ? '1rem' : '0')};
  flex-direction: ${({ variant }) =>
    variant === 'suggestion' ? 'row' : 'column'};
  align-items: ${({ variant }) =>
    variant === 'suggestion' ? 'center' : 'stretch'};
`;

export const ResultImageWrapper = styled.div<VariantProps>`
  width: ${({ variant }) => (variant === 'suggestion' ? '6rem' : '100%')};
  height: ${({ variant }) => (variant === 'suggestion' ? '6rem' : '20rem')};
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
`;

export const TextContent = styled.div<VariantProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ variant }) => (variant === 'suggestion' ? '0.4rem' : '0.8rem')};
  flex: 1;
`;

export const ResultTitle = styled.h3<VariantProps>`
  margin: 0.4rem 0 0 0;
  font-size: ${({ variant }) =>
    variant === 'suggestion' ? '1.3rem' : '1.6rem'};
  color: ${colors.orange[500]};
  font-weight: 600;
  line-height: 1.3;
`;

export const ResultLocation = styled.div`
  font-size: 1.2rem;
  color: ${colors.darkGrey};
  display: flex;
  align-items: center;
  gap: 0.4rem;

  &::before {
    content: '';
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    background-color: ${colors.teal};
    border-radius: 50%;
  }
`;

export const Description = styled.p<VariantProps>`
  margin: ${({ variant }) => (variant === 'suggestion' ? '0.4rem 0 0' : '0')};
  font-size: ${({ variant }) =>
    variant === 'suggestion' ? '1.2rem' : '1.3rem'};
  color: ${colors.darkGrey};
  line-height: 1.4;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: ${({ variant }) => (variant === 'suggestion' ? 2 : 3)};
`;

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 1.2rem 1.6rem;
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: 1.6rem;
  color: ${colors.almostBlack};
  background: ${colors.white};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${colors.orange[300]};
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
  }

  &::placeholder {
    color: ${colors.darkGrey};
  }

  ${media.tablet`
    font-size: 1.4rem;
    padding: 1rem 1.4rem;
  `}
`;

export const ExternalLinkIcon = styled.svg`
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  margin-left: 0.6rem;
  margin-bottom: 0.2rem;
  transition: transform 0.2s ease;

  ${ResultLink}:hover & {
    transform: translateY(-1px);
  }
`;

export const SearchContainer = styled.form`
  position: relative;
  width: 100%;
`;

export const ResultsContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const LoadingIndicator = styled.div`
  position: absolute;
  top: calc(100% + 0.8rem);
  left: 0;
  right: 0;
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  padding: 2rem;
  text-align: center;
  color: ${colors.darkGrey};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  animation: fadeIn 0.2s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${colors.orange[300]};
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Categories = styled.div`
  display: flex;
  gap: 0.6rem;
  flex-wrap: wrap;
  margin-top: 0.4rem;
`;

export const CategoryTag = styled.span`
  padding: 0.3rem 0.8rem;
  background: ${colors.teal[50]};
  color: ${colors.teal[700]};
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 500;
`;

export const TopContent = styled.div<VariantProps>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ variant }) =>
    variant === 'suggestion' ? 'flex-start' : 'stretch'};
  gap: 1.2rem;
  margin-bottom: ${({ variant }) =>
    variant === 'suggestion' ? '0.8rem' : '0'};
`;

export const ResultsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  align-items: stretch;

  ${media.tablet`
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 1.5rem;
  `}
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: clamp(0.8rem, 1.5vw, 1.2rem);
`;

export const TitleGroup = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.6rem;
`;
