import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { SearchInput } from './components/SearchInput';
import { SuggestionsView } from './components/SuggestionsView';
import { useSearch } from './hooks/useSearch';
import {
  SearchWrapper,
  SearchContainer,
  ResultsContainer,
  LoadingIndicator,
  Spinner,
} from './styles/StyledComponents';

export const TourSuggestions: React.FC = () => {
  const { results, isLoading, query, handleSearch } = useSearch();
  const [isFocused, setIsFocused] = useState(false);
  const searchContainerRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (searchContainerRef.current) {
      const rect = searchContainerRef.current.getBoundingClientRect();
      document.documentElement.style.setProperty(
        '--distance-from-top',
        `${rect.top}px`,
      );
    }
  }, []);

  return (
    <SearchWrapper>
      <SearchContainer ref={searchContainerRef}>
        <SearchInput
          value={query}
          onChange={handleSearch}
          onFocus={() => setIsFocused(true)}
        />
        {isLoading && query && isFocused && (
          <LoadingIndicator>
            <Spinner />
            <FormattedMessage
              id="similaritySearching"
              defaultMessage="Searching..."
            />
          </LoadingIndicator>
        )}
        {!isLoading && results && query && isFocused && (
          <ResultsContainer>
            <SuggestionsView results={results} query={query} />
          </ResultsContainer>
        )}
      </SearchContainer>
    </SearchWrapper>
  );
};

export default TourSuggestions;
