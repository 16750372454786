// tslint:disable:variable-name
const product_page = {
  base_price: 'From',
  base_price_note: '*seasonal price may vary',
  location: 'Location',

  'nav.information': 'Information',
  'nav.operator': 'Operator',
  'nav.location': 'Location',
  'nav.summary': 'Tour Summary',

  'booking_banner.continue': 'Continue',

  // TODO: Ask backend to change naming on these for consistency or provide translations.
  'feature_list.Age Group': 'Age groups',
  'feature_list.duration': 'Duration',
  'feature_list.duration.days':
    '{days, plural, =0 {} one {# day} other {# days}}',
  'feature_list.duration.description': '{description}',
  'feature_list.duration.hours':
    '{hh, plural, =0 {} one {# hour} other {# hours}} {mm, plural, =0 {} one {# minute} other {# minutes}}',
  'feature_list.Extra': 'Extra',
  'feature_list.Language': 'Languages',
  'feature_list.Time': 'Time',
  'feature_list.startingTime': 'Starting Time',
  'feature_list.your_preference': 'Your Preference',
  'feature_list.starting_point': 'Starting point',
  'feature_list.starting_point_required':
    'Preferred pick-up location (hotel/cruise ship terminal)',
  'feature_list.Ticket Type': 'Ticket Type',

  'form.title': 'Select Date and Travelers',
  'form.tour_date': 'Tour Date',
  'form.options': 'Options',
  'form.no_available_options':
    'No available options at the selected date. Please select another date.',
  'form.book_now': 'Book now',
  'form.language': 'Language',
  'form.on_request': 'Upon request only',
  'form.ticket_required': 'Please select a ticket',
  'form.ticket_title': 'Participants',
  'form.time': 'Time',
  'form.all_day': 'All day',
  'form.extras': 'Extras ',

  'pricing.minimum_required': 'Minimum {count} required.',
  'pricing.ageGroup': 'Guests',
  'pricing.DEFAULT': 'Guests',
  'pricing.extras': 'Extra',
  'pricing.ticketType': 'Ticket Type',

  'content.continue_reading': 'Continue reading ...',
  'content.show_less': 'Show less',

  'operator.heading': 'Tour Operator',
  'tour_summary.heading': 'Tour Summary',

  'nav.reviews': 'Reviews',
  'reviews.heading': 'Reviews from',
  'reviews.rating': '{count, plural, one {# review} other {# reviews}}',
  'reviews.reviewer':
    '{name, select, undefined {Anonymous} other {{name}}} {location, select, undefined {} other {from {location}}}',
  'reviews.date':
    '{on, select, undefined {} other {Reviewed {on, date, medium}}}',
  'reviews.load_more': 'Load More',
  'reviews.reviewed_by': 'Reviewed by a Tripadvisor traveler',
  'reviews.on': 'on',
  'reviews.read_full_review': 'Read full review on TripAdvisor',
  'reviews.loading': 'Loading more reviews...',

  'summary.heading': 'Summary',
  'summary.ticket_count':
    '{quantity}x {title, select, undefined {Ticket} other {{title}}}',
  'summary.total': 'Total',
};

// tslint:enable:variable-name, object-literal-sort-keys
export default Object.entries(product_page).reduce((a, [k, v]) => {
  const key = `product_page.${k}`;
  a[key] = v;
  return a;
}, {});
