import React from 'react';
import { useIntl } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { SearchInput as StyledInput } from '../styles/StyledComponents';
import { colors } from 'common/styles';

interface SearchInputProps extends RouteComponentProps {
  value: string;
  onChange: (value: string) => void;
  onFocus: () => void;
}

const SearchInputComponent: React.FC<SearchInputProps> = ({
  value,
  onChange,
  onFocus,
  history,
}) => {
  const intl = useIntl();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (value.trim()) {
      history.push(
        `/${intl.locale}/search?q=${encodeURIComponent(value.trim())}`,
      );
    }
  };

  return (
    <InputWrapper onSubmit={handleSubmit}>
      <StyledInput
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        onFocus={onFocus}
        onClick={onFocus}
        placeholder={intl.formatMessage({
          defaultMessage: 'Search',
          id: 'Search',
        })}
      />
      <SearchButton type="submit" aria-label="Search">
        <SearchIcon />
      </SearchButton>
    </InputWrapper>
  );
};

export const SearchInput = withRouter(SearchInputComponent);

const InputWrapper = styled.form`
  position: relative;
  width: 100%;
`;

const SearchButton = styled.button`
  position: absolute;
  right: 1.2rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.orange[500]};
  transition: color 0.2s ease;

  &:hover {
    color: ${colors.orange[600]};
  }
`;

const SearchIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8" />
    <line x1="21" y1="21" x2="16.65" y2="16.65" />
  </svg>
);
