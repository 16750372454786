import React from 'react';
import { useIntl } from 'react-intl';

import { LocationSearchResult, TourSearchResult } from '../types/types';
import {
  ResultItem as StyledResultItem,
  ResultLink,
  ExternalLinkIcon,
  Description,
  ResultContent,
  ResultTitle,
  ResultLocation,
  ResultImage,
  ResultImageWrapper,
  TextContent,
  Categories,
  CategoryTag,
  TopContent,
} from '../styles/StyledComponents';

interface ResultItemProps {
  result: TourSearchResult | LocationSearchResult;
  variant?: 'suggestion' | 'result';
}

export const ResultItem: React.FC<ResultItemProps> = ({
  result,
  variant = 'suggestion',
}) => {
  const { locale } = useIntl();
  const isTour = 'teaserImageUrl' in result;

  const getResultUrl = (result: TourSearchResult | LocationSearchResult) => {
    const city = result.location.toLowerCase().split(',')[0];
    const tourName = result.name
      .toLowerCase()
      // Replace dots, spaces, commas, or multiple hyphens with a single hyphen
      // and trim any leading/trailing hyphens in one go
      .replace(/^[-.,\s]+|[-.,\s]+$|[.,\s]+/g, '-')
      .replace(/-+/g, '-')
      .replace(/-+$/g, '');

    return `/${locale}/tours${isTour ? `/${city}` : ''}/${tourName}-${
      result.id
    }`;
  };

  return (
    <StyledResultItem variant={variant}>
      <ResultLink
        href={getResultUrl(result)}
        target={variant === 'suggestion' ? '_blank' : undefined}
        variant={variant}
      >
        <TopContent variant={variant}>
          <ResultContent variant={variant}>
            {isTour && (
              <ResultImageWrapper variant={variant}>
                <ResultImage
                  src={(result as TourSearchResult).teaserImageUrl}
                  alt={result.name}
                  loading="lazy"
                />
              </ResultImageWrapper>
            )}
            <TextContent variant={variant}>
              <ResultTitle variant={variant}>{result.name}</ResultTitle>
              <ResultLocation>{result.location}</ResultLocation>
              {isTour && variant === 'result' && (
                <Categories>
                  {(result as TourSearchResult).categories.map(category => (
                    <CategoryTag key={category.id}>{category.name}</CategoryTag>
                  ))}
                </Categories>
              )}
              {variant === 'result' && (
                <Description variant={variant}>
                  {result.description}
                </Description>
              )}
            </TextContent>
          </ResultContent>
          {variant === 'suggestion' && <ExternalLinkIcon />}
        </TopContent>
        {variant === 'suggestion' && (
          <Description variant={variant}>{result.description}</Description>
        )}
      </ResultLink>
    </StyledResultItem>
  );
};
