import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import media from 'mediaTemplate';

import { Header, Subheader } from 'search/elements';

import SimilaritySearch from '../components/SimilaritySearch';

interface Props {
  locale: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  z-index: 20;
  border-radius: 4px;
  align-items: center;
  position: relative;
  width: 100%;

  ${media.desktop`
    margin: 5rem 0 6.5% 0;
  `};

  ${media.tablet`
    align-items: center;
    padding: 0 1rem;
  `};
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 100;
`;

const MainSearchBox: React.FunctionComponent<Props> = () => (
  <Wrapper data-testid="main-search-box">
    <Header color="#333">
      <FormattedMessage
        id="GoTravelDiscover"
        defaultMessage="Go. Travel. Discover."
      />
    </Header>
    <Subheader color="#333">
      <FormattedMessage
        id="FindATripBookAnAdventure"
        defaultMessage="FIND A TRIP. BOOK AN ADVENTURE."
      />
    </Subheader>
    <SearchContainer>
      <SimilaritySearch />
    </SearchContainer>
  </Wrapper>
);

export default MainSearchBox;
