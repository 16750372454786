import checkoutPage from './checkout_page.de';
import footer from './footer.de';
import header from './header.de';
import productPage from './product_page.de';
import suppliers from './suppliers.de';
import travelcouper from './travelcouper.de';

const messages = {
  ...checkoutPage,
  ...footer,
  ...header,
  ...productPage,
  ...suppliers,
  ...travelcouper,
  AboutUs: 'Über uns',
  AcceptedPaymentMethodsColon: 'Akzeptierte Zahlungsmöglichkeiten:',
  AccountStatusAndRecentActivity: 'Mitgliedsstatus und bisherige Vorgänge',
  Activities: 'Aktivitäten',
  Activity: 'Aktivität',
  AddGuests: 'Gäste hinzufügen',
  AddGuestsParticipantsColon:
    'Gäste hinzufügen (es ist mindestens der Name des Hauptreisenden anzugeben):',
  Address: 'Adresse',
  AddRoom: 'Zimmer hinzufügen',
  Adults: 'Erwachsene',
  All: 'ALLE',
  AllYearRound: 'das ganze Jahr',
  AlreadyHaveAnAccount: 'Haben Sie bereits einen Account?',
  And: 'und',
  Any: 'Beliebig',
  Attractions: 'Sehenswürdigkeiten',
  Back: 'Zurück',
  BackToMagazine: 'Zurück zum Magazin',
  BackToResults: 'ZURÜCK ZU DEN ERGEBNISSEN',
  BecomeAPartner: 'Werden Sie Partner',
  BookAdventure: 'Finde eine Tour. Buche ein Erlebnis!',
  BookBy: 'Buchen bis zum',
  BookingDetails: 'Buchungsdetails',
  BookingsAndReservations: 'Buchungen und Reservationen',
  BookNow: 'Jetzt buchen',
  BookThisTour: 'Buchen Sie diese Tour',
  Breakfast: 'Frühstück',
  Budget: 'BUDGET',
  By: 'von',
  ByName: 'nach Name',
  CancellationRulesColon: 'Stornierungsbedingungen:',
  Categories: 'Kategorien',
  Checkout: 'Checkout',
  CheckoutAsGuest: 'Als Gast fortfahren',
  CheckoutTravelcoupMagazine:
    'Lassen Sie sich vom Travelcoup Magazin inspirieren',
  Children: 'Kinder',
  ChooseYour: 'Finden Sie die besten',
  ChooseYourPort: 'Wählen Sie Ihren Hafen',
  City: 'Stadt',
  CityPasses: 'City Pässe',
  Collaboration: 'ARBEITEN SIE MIT UNS',
  CommentsSpecialRemarks: 'Besondere Anmerkungen',
  Company: 'Unternehmen',
  CompareDeals: 'Finde und vergleiche die besten Angebote',
  Confirmation: 'Bestätigung',
  ContactUs: 'Kontakt',
  Continue: 'WEITER',
  ContinueAsA: 'Weiter als oder',
  CreateAnAccount: 'einen Account erstellen',
  Cruise: 'Kreuzfahrt',
  CruiseLine: 'Reederei',
  Dashboard: 'Dashboard',
  Dates: 'Daten',
  DaysInitial: 'T',
  DaysParenthesis: 'Tag(e)',
  DealsIn: 'angebote in',
  Destinations: 'Destinationen',
  Details: 'Details',
  DidntGetVerificationEmail: 'Haben Sie keine Verifizierungsemail bekommen?',
  Discover: 'Entdecken',
  DiscoverHotelsIn: 'Unsere Hotelangebote in',
  DiscoverMoreHotelsIn: 'Entdecken Sie weitere Hotelangebote',
  DiscoverMoreToursActivities: 'Entdecke mehr Touren und Aktivitäten',
  DiscoverToursIn: 'Entdecke Touren und Aktivitäten in',
  DoYouHave: 'Haben Sie einen',
  EditYourAccountDetails: 'Profilinformationen bearbeiten',
  EgHotelName: 'z.B. Hotel Name',
  EMail: 'E-mail',
  EnterDestination: 'Beliebte Ausflugsziele',
  EnterYourEmail: 'Email Adresse',
  Extra: 'EXTRAS',
  Faq: 'FAQ',
  Filter: 'Filtern',
  FilterResults: 'Ergebnisse filtern',
  FindAHotelIn: 'Finde ein Hotel in',
  FindATripBookAnAdventure: 'FIND A TRIP. BOOK AN ADVENTURE.',
  FindCompareBestDeals: 'Finden und vergleichen Sie die besten Angebote!',
  FirstName: 'Vorname',
  Found: 'GEFUNDEN',
  FreeCancellation: 'Kostenlose Stornierung',
  FrequentlyAskedQuestions: 'Häufig gestellte Fragen',
  From: 'VON',
  From_For_Prices: 'ab',
  FullAddress: 'Strasse und Hausnummer',
  FullBoard: 'Vollpension',
  FullName: 'Name',
  GeneralTermsAndConditions: 'Allgemeine Geschäftsbedingungen',
  GoTravelDiscover: 'Go. Travel. Discover.',
  GreatSavings: 'Grossartige Ersparnisse',
  Guest: 'Gast',
  HalfBoard: 'Halbpension',
  Highlights: 'Highlights',
  HighToLow: 'Preis absteigend',
  HintsAndTips: 'Hinweise & Tipps',
  Home: 'HOME',
  HotelDescription: 'Hotel Beschreibung',
  HotelName: 'Hotel Name',
  Hotels: 'Hotels',
  HotelTransfer: 'Hoteltransfer',
  HoursParenthesis: 'Stunde(n)',
  IfYourBookingIsFlaggedAsOnRequest:
    'Wenn es sich um eine on request Buchung handelt, werden wir uns sofort nach dem Checkout um die Bestätigung des Anbieters kümmern. Sollte eine Tour einmal nicht verfügbar sein, erstatten wir Ihnen den Kaufbetrag umgehend zurück',
  Important: 'Wichtiges',
  InCaseTheTourIsNotAvailable:
    'Falls Ihre Tour nicht verfügbar ist, erstatten wir Ihnen umgehend Ihr Geld zurück.',
  InspireYourSelfTravelTheWorld:
    'Lass dich inspirieren, um die Welt zu reisen!',
  Languages: 'SPRACHEN',
  LastName: 'Nachname',
  Latest: 'letzte',
  LatestReservations: 'Neueste Buchungen',
  Legal: 'RECHTLICHES',
  LegalDisclosures: 'Impressum',
  Loading: 'laden',
  LoadMoreTopDestinations: 'WEITERE TOP DESTINATIONEN LADEN',
  LoadMoreTours: 'WEITERE TOUREN LADEN',
  LocationMap: 'Karte',
  Login: 'Einloggen',
  LogoutLinkExMark: 'Ausloggen!',
  LowToHigh: 'Preis aufsteigend',
  Magazine: 'Magazin',
  MessageToTravelcoup: 'Schreiben Sie Travelcoup eine Nachricht',
  MessageToTravelcoupDots: 'Nachricht an Travelcoup ...',
  MobileVouchersAccepted: 'Mobiles Ticket',
  MoreActivities: 'mehr Aktivitäten',
  MoreInformation: 'Mehr Informationen',
  MostCommonQuestonsAnswered: 'am häufigsten gestellte Fragen',
  MostLovedHotels: 'MOST LOVED HOTELS',
  MostLovedTours: 'MOST LOVED TOURS',
  MyAccount: 'Mein Profil',
  MyReservations: 'Meine Buchungen',
  NameOfParticipant: 'Reisender',
  Nationality: 'Staatsangehörigkeit',
  NeverSettleNeverRest: 'Never Settle. Never Rest.',
  NewsLetter: 'Newsletter',
  NextAvailableDate: 'Der nächste verfügbare Tag ist',
  Night: 'Nacht',
  NoCreditCardFees: 'Keine Kreditkartengebühren',
  NonRefundable: 'Nicht erstattbar',
  NoRoomsFound:
    'Keine verfügbaren Hotels gefunden. Bitte ändern Sie die Suchkriterien und versuchen es erneut.',
  NoteColon: 'Zusätzliche Informationen:',
  NoThanks: 'Nein, danke.',
  Now: 'JETZT',
  OfferExpiresInColon: 'Dieses Angebot ist gültig bis:',
  On: 'am',
  OnManyRooms: 'bei den meisten Zimmern',
  OnRequestBooking:
    'Duch die kurzfristige Buchung müssen wir hier leider eine Verfügbarkeitsanfrage nach der Buchung vornehmen. In den meisten Fällen sollten noch Plätze verfügbar sein und Sie sollten eine Bestätigung innerhalb weniger Stunden erhalten. Sollte der Anbieter keine Verfügbarkeit mehr haben, werden wir Ihnen die Buchung sofort und ohne Kosten wieder stornieren.',
  Open: 'offen',
  Or: 'oder',
  OrderNumberColon: 'Bestätigungsnummer:',
  PartnerAndTourOperatorExtranet:
    'Partner & Touranbieter/Reiseanbieter Extranet',
  Payment: 'Bezahlung',
  PaymentFreeOfCharge: 'Kostenfreie Bezahlung',
  PhoneNumber: 'Telefonnummer (z.B. +4960214229590)',
  PickupIncluded: 'Inklusive Abholung',
  PleaseEnterResidenceInformaition: 'Bitte geben Sie Ihren Hotelnamen an',
  PleaseEnterStartingTime: 'Bitte geben Sie Ihre gewünschte Startzeit an',
  PleaseNoteThatSinceOnRequest:
    'Bitte beachten Sie, dass Ihre Buchung "auf Anfrage" ist. Wir werden den Touranbieter für Sie kontaltieren und melden uns innerhalb der nächsten 24 Stunden bei Ihnen.',
  PleaseVerifiyYourEmail:
    'Bitte bestätigen Sie Ihre E-Mail Adresse um Ihre Buchung(en) zu sehen und um Ihre Profilinformationen zu ändern.',
  PleaseVerifyYourEmailHistory:
    'Bitte bestätigen Sie Ihre E-Mail Adresse um Ihre Buchungshistorie zu sehen',
  PopularDestinations: 'Beliebte Ziele',
  Port: 'Hafen',
  PostalCode: 'PLZ',
  PreferredStartingPoint: 'Gewünschter Abholort (Hotel/Kreuzfahrtterminal)',
  PressRelease: 'Pressemeldungen',
  PrintPaymentConfirmation: 'drucken Sie die Zahlungsbestätigung aus',
  PrintTicketsAtHome: 'Ticket drucken @home',
  Privacy: 'Datenschutzrichtlinien',
  ProceedToCheckout: 'Weiter zum Checkout',
  processingDots: 'In Bearbeitung…',
  ProvideCardInformationBelowColon: 'Geben Sie Ihre Kreditkartennummer ein:',
  Rating: 'Bewertung',
  RecentReservations: 'neueste Buchungen',
  RedeemVoucherCode: 'Voucher Code einlösen',
  RefineSearch: 'Verfeinere deine Suche',
  Releases: 'Veröffentlichung',
  ResendVerificationEmail: 'Verifizierungsemail erneut zusenden',
  ReviewedBy:
    'rezensiert von {name, select, undefined {Anonym} other {{name}}}',
  Reviews: 'Bewertungen',
  'room.many': 'zimmer',
  'room.one': 'zimmer',
  RoomAmenities: 'Zimmerausstattung',
  RoomOnly: 'nur Zimmer',
  Rooms: 'Zimmer',
  RoutePlan: 'Reiseroute',
  Save: 'Sparen',
  Search: 'Suchen',
  SearchResultsFor: 'Suchergebnisse für "{query}"',
  Select: 'Auswählen...',
  SelectADate: 'WÄHLEN SIE EIN DATUM',
  SelectCountry: 'Wählen Sie Ihr Land',
  SelectLanguage: 'WÄHLEN SIE EINE SPRACHE',
  SelectTime: 'WÄHLEN SIE EINE UHRZEIT',
  Send: 'Senden',
  SendMessage: 'Nachricht senden',
  Ship: 'Schiff',
  ShoreExcursions: 'Landausflüge',
  ShowAll: 'Alles anzeigen',
  ShowLess: 'zeige weniger',
  ShowMore: 'zeige mehr',
  SignUpForOurNewsletter: 'NEWSLETTER ABONNIEREN',
  Sleeps: 'Personen',
  SortBy: 'SORTIEREN NACH',
  SpecialPromotion: 'SONDERANGEBOT',
  SpecialRequests:
    'Haben Sie besondere Wünsche, die Sie uns mitteilen möchten?',
  'SpinnerWait-hotels':
    'Bitte warten Sie einen Augenblick. Wir suchen die besten Hotels für Sie.',
  'SpinnerWait-tours':
    'Bitte warten Sie einen Augenblick. Wir suchen die besten Touren für Sie.',
  Stars: 'STERNE',
  StartingFrom: 'AB',
  StartingPoint: 'Startpunkt',
  StartingPointColon: 'Startpunkt:',
  StartingTime: 'Startzeit',
  StateProvince: 'Bundesland/Kanton/Region',
  Street: 'Strasse',
  SubmitPayment: 'Zahlung bestätigen',
  subtotal: 'Zwischensumme',
  Summary: 'ZUSAMMENFASSUNG',
  TariffNotes: 'Tarif-Infos',
  TermsOfUse: 'Nutzungsbedingungen',
  ThankYouForChoosingTravelcoupConfirmation:
    'Danke, dass Sie sich für Travelcoup entschieden haben. Die Transaktion ist abgeschlossen. Unten finden Sie Ihre Tickets und die Zahlungsbestätigung. Mit freundlichen Grüßen, Ihr Travelcoup-Team',
  ThankYouForChoosingTravelcoupSteps:
    'Danke, dass Sie sich für Travelcoup entschieden haben. Um die Buchung abzuschliessen, füllen Sie bitte das untenstehende Formular aus und wählen Sie Ihr bevorzugtes Zahlungsmittel. Nach erfolgreichem Checkout erhalten Sie sofort Ihren Tour/Hotel-Voucher per E-mail.',
  ThankYouForPurchasingATicket:
    'Danke für Ihre Buchung auf travelcoup.com. Die Transaktion ist abgeschlossen.',
  Ticket: 'TICKETS',
  Time: 'UHRZEIT',
  TopDestinations: 'Beliebte Ziele',
  TopSeller: 'TOPSELLER',
  TotalColon: 'Gesamt:',
  TourOperatedByColon: 'Touranbieter:',
  TourOperator: 'Touranbieter',
  Tours: 'Touren',
  ToursAndActivities: 'Touren und Aktivitäten',
  ToursAndActivitiesIn: 'Touren und Aktivitäten in',
  ToursAvailable: 'Touren verfügbar',
  ToursForCruises: 'Touren für Kreuzfahrten',
  Travel: 'Reisen',
  TravelBy: 'Reisen bis zum',
  Travelcouper: 'Welcher Travelcouper bist du?',
  TravelcoupInTheNews: 'Travelcoup in den Nachrichten',
  TravelcoupVoucherCode: 'Travelcoup Voucher Code',
  TravelerNames: 'Namen der Reisenden',
  TravelExperienceAsUniqueAsYouAre: 'TRAVEL EXPERIENCES AS UNIQUE AS YOU ARE.',
  TravelscapeLLCTerms: 'Travelscape LLC. Terms',
  Type: 'Typ',
  Until: 'BIS',
  UponRequestChosen: 'Das ausgewählte Datum ist auf Anfrage',
  UponRequestOnly: 'auf Anfrage',
  ViewAll: 'Alle anzeigen',
  ViewMap: 'Karte',
  ViewMoreToursAndActivities: 'ENTDECKE WEITERE TOURS & ACTIVITIES',
  Was: 'WAR',
  YouHaveNotBookedAnyTour:
    'Sie haben bisher noch keine Tour oder Hotel gebucht',
  YouMightLike: 'Das könnte Ihnen auch gefallen',
  YourInformation: 'Deine Daten',
  YourPreference: 'Gewünschte Zeit',
  YouWillReceiveAnEmailWithThePayment:
    'Sie erhalten in Kürze eine Email mit Ihrer Zahlungsbestätigung.',

  // tours for cruises page
  BookShoreExcursions: 'Buchen Sie Ihre Landausflüge',
  NumberOne: 'Die #1 für Ihre Landausflüge',

  Customizable: 'Flexibilität',
  CustomizableText:
    'Buchen Sie die Tour, die am besten zu Ihnen passt und erkunden Sie jeden Hafen nach Ihren Wünschen',

  GreatDeals: 'Grossartige Angebote',
  GreatDealsText: 'Travelcoup hat die besten Touren zum besten Preis.',

  IndividualTour: 'Individuelle Touren',
  IndividualTourText:
    'keine langen Warteschlangen oder unangenehme Zusatzverpflichtungen',

  MakeTheMost: 'Machen Sie das Beste aus Ihrer Schiffsreise',

  Partnered: 'Partner',
  PartneredText:
    'Travelcoup arbeitet Hand in Hand mit den Reedereien, weswegen Sie sich keine Gedanken machen müssen, dass Sie das Schiff verpassen',

  RiverCruises: 'Flusskreuzfahrt',
  SeaCruises: 'Hochseekreuzfahrt',

  Why: 'Warum',

  AidaAdventure: 'Ihr Kreuzfahrt-Erlebnis beginnt hier',
  AidaHighlights: 'Entdecke die AIDA Highlights 2020',
  AndCruise: 'und eine Reise',
  AndShip: 'und ein Schiff',
  PickCruiseLine: 'Wähle eine Reederei',
  RiverAdventure: 'Flusskreuzfahrt-Erlebnis beginnt hier',
  VivaHighlights: 'Entdecke die VIVA Highlights 2019',

  Arrive: 'Ankunft',
  Ashore: 'LANDPROGRAMM.',
  Depart: 'Abfahrt',
  DiscoverShoreExcursions:
    'Entdecke die Städte deiner Kreuzfahrt mit individuellen Landausflügen',
  YourIndividualProgram: 'Ihr individuelles ',

  PleaseSelect: 'Bitte wählen Sie',
  PleaseSelectMore: 'Bitte wählen Sie noch {remaining}',
  YourSelected: 'Ihre ausgewählten',

  promo: '{price} sparen',

  Billed: 'Auf Rechnung',
  CheckoutMethod: 'Wählen Sie Ihre Bezahlmethode',
  Direct: 'Per Kreditkarte',

  SelectDifferentTimeFor: 'Wählen Sie eine andere Zeit für',

  Error404: '404-Fehler',
  Oops: 'OOPS',
  PageNotFound: 'Wir konnten die von Ihnen angeforderte Seite nicht finden',
  SomethingWentWrong: 'Da hat sich der Fehlerteufel eingeschlichen!',
  Wishlist: 'Wunschzettel',
  YourWishlist: 'Deine Wunschliste',

  NewsletterSuccess:
    'Bitte checken Sie Ihre Emails und bestätigen Sie Ihre Anmeldung.',
  AlreadySubscribed: 'bereits abonniert',

  PleaseWait: 'Warten Sie mal...',
  ChooseOne: 'Wähle eins',
  SoldOut: 'Leider nicht mehr verfügbar',
  options: 'Optionen',
  SelectAnOption: 'Bitte wählen Sie eine der folgenden Optionen',
  SeatingSections: 'Sitzabschnitte',

  OurInspiration: 'Inspirationen',
  ForYou: 'für Sie',
  'reviews.submit': 'Bewertung abschicken',
  'reviews.rate-your-experience': 'Bewerten Sie Ihre Erfahrung',

  MultiDayTours: 'mehrtägige Touren',
  SingleDayTours: 'Tagestouren',

  home: 'Startseite',
  top_destinations: 'Top Destinationen',
  about_us: 'Über uns',
  affiliateTermsAndConditions: 'Affiliate-Geschäftsbedingungen',
  affiliateProgramTermsAndConditions:
    'Allgemeine Geschäftsbedingungen für das Partnerprogramm',
  tableOfContents: 'Inhaltsverzeichnis',
  'generalTermsAndConditions:': 'Allgemeine Geschäftsbedingungen',
  Locations: 'Orte',
  results: 'Ergebnisse',

  // Search suggestions
  similaritySearchCount:
    '{count} {count, plural, one {Ergebnis} other {Ergebnisse}}',
  similaritySearchToursTitle: 'Passende Touren',
  similaritySearchLocationsTitle: 'Passende Reiseziele',
  similarityShowMoreResults: 'Mehr Ergebnisse',
  similaritySearching: 'Suche...',
  similarityNoQuery: 'Bitte geben Sie einen Suchbegriff ein',
  similarityNoResults: 'Keine Ergebnisse gefunden für "{query}"',
  similarityResultsFor: 'Suchergebnisse für "{query}"',
};

export default messages;
