import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { addDays } from 'date-fns';

import FeaturedSection from 'common/containers/FeaturedSection';
import { searchOperations } from 'search/duck';

import ArrowDown from '../../assets/svg/ArrowSlimDownDashedBlackSmall';

import media from 'mediaTemplate';

import data from 'assets/data/toursAndActivities.json';
import data_de from 'assets/data/toursAndActivities_de.json';
import { tourOperations } from 'tours/duck';
import { FeaturedTour } from 'typedefs/tours';
import { SBox } from 'common/components/skeletons';

type ReduxProps = ConnectedProps<typeof connector>;
type Props = ReduxProps;

const FeaturedTours: React.FunctionComponent<Props> = ({
  featuredTours,
  loaded,
  requestFeaturedTours,
  setDateFrom,
  setDateTo,
  currency,
  locale,
  loading,
  error,
}) => {
  const intl = useIntl();

  const staticList = intl.locale === 'en' ? data : data_de;
  const list = error ? staticList.tourSummaryList : featuredTours;

  // store 'loaded' state and silently refetch featured tours
  // without showing skeleton cards
  const alreadyLoaded = React.useRef(loaded);

  React.useEffect(() => {
    requestFeaturedTours();
  }, [currency, locale, requestFeaturedTours]);

  return (
    <FeaturedSection hideButton={true}>
      {() => {
        const limit = featuredTours.length;

        const handleClick = () => {
          const today = new Date();
          setDateFrom(today);
          setDateTo(addDays(today, 7));
        };

        return (
          <>
            <Wrapper>
              <section id="main-block">
                <div>
                  <div className="text-center">
                    <h1 className="main-title blue-title-sm">
                      <FormattedMessage id="Tours" defaultMessage="Tours">
                        {text => <span className="gray">{text}</span>}
                      </FormattedMessage>{' '}
                      <FormattedMessage id="And" defaultMessage="and">
                        {text => <span className="gray">{text}</span>}
                      </FormattedMessage>{' '}
                      <FormattedMessage
                        id="Activities"
                        defaultMessage="activities"
                      >
                        {text => (
                          <span className="upper" style={{ color: '#000' }}>
                            {text}
                          </span>
                        )}
                      </FormattedMessage>
                    </h1>
                    <p className="text-center">
                      <FormattedMessage
                        id="BookAdventure"
                        defaultMessage="Find a trip. Book an adventure!"
                      >
                        {text => <strong>{text}</strong>}
                      </FormattedMessage>
                    </p>
                  </div>
                </div>
              </section>
            </Wrapper>
            <ToursGrid>
              {list.length
                ? (list as FeaturedTour[]).slice(0, limit).map(tour => (
                    <GridCell key={tour.tourId}>
                      <StyledLink
                        onClick={handleClick}
                        to={`/${intl.locale}/${tour.normalizedUrlPath}`}
                      >
                        <Image src={tour.teaserImageUrl} alt="" />
                        <ArrowDownSmall>
                          <ArrowDown />
                        </ArrowDownSmall>
                        <TourDescriptionFeatured>
                          {/* <TourType>{tour.category}</TourType> */}
                          <TourName>{tour.tourName}</TourName>
                          <TourCity>
                            {tour.location}, {tour.locationCountry}
                          </TourCity>
                        </TourDescriptionFeatured>
                      </StyledLink>
                    </GridCell>
                  ))
                : loading &&
                  !alreadyLoaded.current && (
                    <>
                      <GridCell>
                        <SBox flex={1} height={4} maxWidth={5} mr={5} />
                      </GridCell>
                      <GridCell>
                        <SBox flex={1} height={4} maxWidth={5} mr={5} />
                      </GridCell>
                      <GridCell>
                        <SBox flex={1} height={4} maxWidth={5} mr={5} />
                      </GridCell>
                    </>
                  )}
            </ToursGrid>
          </>
        );
      }}
    </FeaturedSection>
  );
};

const Wrapper = styled.div`
  margin: 4rem 0;
  ${media.small`
   margin: 4rem 0 0;
 `};
`;

const GridCell = styled.div`
  position: relative;
  width: 100%;
  max-width: 35rem;
  margin: 2rem;
  ${media.phone`
   margin: 2rem 0;
 `};
  :hover {
    img {
      box-shadow: #8fe4e2 15px 15px;
    }
  }
`;

const Image = styled.img`
  transition: all 0.35s;
  border-radius: 4px;
  box-shadow: #fff 15px 15px;
  margin: 0;
  padding: 0px;
  height: 35rem;
  object-fit: cover;
`;

const ToursGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ArrowDownSmall = styled.div`
  text-align: left;
  margin: 8px 0 0 24px;
`;

const TourDescriptionFeatured = styled.div`
  width: 80%;
  text-align: left;
  font-size: 2rem;
  margin: 1rem 0 0 3rem;
  ${media.tablet`
    width: 100%;
    display: block;
    margin: 0 0 0 2rem;
  `};
`;

const TourName = styled.div`
  font-family: NewsCycle, sans-serif;
  color: #303030;
  font-size: 2rem;
  margin: 0.5rem 0;
  ${media.tablet`
    margin: 0;
  `};
`;

const TourCity = styled.div`
  text-transform: uppercase;
  color: #303030;
  letter-spacing: 1.75%;
  ${media.desktop`
    font-size: 1.25rem;
    margin: 1rem 0;
  `};
`;

const StyledLink = styled(Link)`
  box-shadow: #fff 15px 15px;
  :hover {
    box-shadow: #8fe4e2 15px 15px;
  }
  :active {
    text-decoration: none;
  }
  :hover {
    text-decoration: none;
  }
`;

const mapState = (state: RootState) => ({
  featuredTours: state.tours.featuredTours,
  loaded: state.tours.featuredToursState === 'loaded',
  loading: state.tours.featuredToursState === 'loading',
  error: state.tours.featuredToursState === 'error',
  currency: state.currency.currency,
  locale: state.intl.locale,
});

const mapDispatch = {
  setDateFrom: searchOperations.setDateFrom,
  setDateTo: searchOperations.setDateTo,
  requestFeaturedTours: tourOperations.requestFeaturedTours,
};

const connector = connect(mapState, mapDispatch);
export default connector(FeaturedTours);
