import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ResultItem } from './ResultItem';
import { SearchResults } from '../types/types';
import {
  ResultsWrapper,
  SectionCount,
  ColumnsContainer,
  Column,
} from '../styles/StyledComponents';
import { colors } from 'common/styles';

interface OwnProps {
  results: SearchResults;
  query: string;
}

export const SuggestionsView: React.FC<OwnProps> = ({ results, query }) => {
  const showToursFirst = results.bestMatchType === 'tour';
  const primaryResults = showToursFirst
    ? results.tours.slice(0, 3)
    : results.locations.slice(0, 8);
  const secondaryResults = showToursFirst
    ? results.locations.slice(0, 8)
    : results.tours.slice(0, 3);
  const intl = useIntl();

  const getTotalCount = (count: number) => {
    return intl.formatMessage(
      {
        id: 'similaritySearchCount',
        defaultMessage: '{count} {count, plural, one {match} other {matches}}',
      },
      { count },
    );
  };

  const getSectionTitle = (type: 'tours' | 'locations') => {
    return intl.formatMessage({
      id:
        type === 'tours'
          ? 'similaritySearchToursTitle'
          : 'similaritySearchLocationsTitle',
      defaultMessage:
        type === 'tours' ? 'Matching tours' : 'Matching destinations',
    });
  };

  return (
    <ResultsWrapper>
      <ColumnsContainer>
        <Column>
          <SectionHeader>
            <HeaderContent>
              <TitleGroup>
                <SectionTitle>
                  {getSectionTitle(showToursFirst ? 'tours' : 'locations')}
                </SectionTitle>
                <SectionCount>
                  ({getTotalCount(primaryResults.length)})
                </SectionCount>
              </TitleGroup>
              <ShowMoreLink
                to={`/${intl.locale}/search?q=${encodeURIComponent(query)}`}
              >
                {intl.formatMessage({
                  id: 'similarityShowMoreResults',
                  defaultMessage: 'More results',
                })}
                <ArrowIcon />
              </ShowMoreLink>
            </HeaderContent>
          </SectionHeader>
          {primaryResults.map((result, index) => (
            <ResultItem
              key={`primary-${index}`}
              result={result}
              variant="suggestion"
            />
          ))}
        </Column>

        <Column>
          <SectionHeader>
            <HeaderContent>
              <TitleGroup>
                <SectionTitle>
                  {getSectionTitle(showToursFirst ? 'locations' : 'tours')}
                </SectionTitle>
                <SectionCount>
                  ({getTotalCount(secondaryResults.length)})
                </SectionCount>
              </TitleGroup>
              <ShowMoreLink
                to={`/${intl.locale}/search?q=${encodeURIComponent(query)}`}
              >
                {intl.formatMessage({
                  id: 'similarityShowMoreResults',
                  defaultMessage: 'More results',
                })}
                <ArrowIcon />
              </ShowMoreLink>
            </HeaderContent>
          </SectionHeader>
          {secondaryResults.map((result, index) => (
            <ResultItem
              key={`secondary-${index}`}
              result={result}
              variant="suggestion"
            />
          ))}
        </Column>
      </ColumnsContainer>
    </ResultsWrapper>
  );
};

const SectionHeader = styled.div`
  position: relative;
  margin-bottom: 1.6rem;
  padding-bottom: 0.8rem;
  border-bottom: 2px solid ${colors.teal};
  width: 100%;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const TitleGroup = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.8rem;
`;

const SectionTitle = styled.h2`
  color: ${colors.orange[300]};
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
`;

const ShowMoreLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: ${colors.orange[500]};
  font-size: 1.4rem;
  text-decoration: none;
  transition: color 0.2s ease;
  white-space: nowrap;

  &:hover {
    color: ${colors.orange[600]};
  }
`;

const ArrowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 12l4-4-4-4" />
  </svg>
);
